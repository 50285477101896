import _ from 'lodash';
import './../sass/main.scss';

window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

require ('./typographer/jquery.typographer.min.pack.js');
require ('./typographer/hyph-pl.min.js');

require('slick-carousel');
require('jquery-aniview');
require ('../../node_modules/slicknav/jquery.slicknav.js');
require('cookie-warn');

jQuery.noConflict();

var cookieSBF;

jQuery.event.special.touchstart = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.touchmove = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.wheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("wheel", handle, { passive: true });
	}
};
jQuery.event.special.mousewheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("mousewheel", handle, { passive: true });
	}
};

jQuery(document).ready(function(){
	
	jQuery('body img').addClass('img-fluid');
	
	jQuery('body').typographer_orphan({
	  forbidden: ["a", "i", "o", "u", "w", "z", "oraz", "dla"]
	});
	
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	  return new bootstrap.Popover(popoverTriggerEl)
	});
	
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	  return new bootstrap.Tooltip(tooltipTriggerEl)
	});
	
	jQuery('.main-form label').click(function() {
	  jQuery(this).siblings('input').focus();
	});
	jQuery('.main-form .form-control').focus(function() {
	  jQuery(this).siblings('.main-form label').hide();
	});
	jQuery('.main-form .form-control').blur(function() {
	  var $thisFc = jQuery(this);
	  if ($thisFc.val().length == 0)
		jQuery(this).siblings('.main-form label').show();
	});
	
	// Do #main-menu-top START

	if(jQuery(window).width() > 1000) {

		jQuery('#menu-top-ul > li > ul').each(function() {
			var megaUl = jQuery(this);
			if(megaUl.children('li').length > 2) {
				megaUl.width(860);
			}
		});

		jQuery('#menu-top-ul > li').each(function () {
			if(jQuery(this).hasClass('current-menu-item')) {
				jQuery(this).find('a').not('.href-after').first().addClass('active');
			}
			jQuery(this).append('<div class="href-after"></div>');
			if(jQuery(this).children('ul').length) {
				jQuery(this).addClass('is-mega-menu');
			}
		});

	}
	
	// Do #main-menu-top STOP

	if(jQuery(window).width() > 1000) {

		jQuery('.aniview').AniView({
			animateClass: 'animate__animated', // for v3 or 'animate__animated' for v4
			animateThreshold: 10,
			scrollPollInterval: 10
		});

	}
	
	//Sticky START

	if(jQuery(window).width() > 1000) {

		//var stickyNavTop = $('.nav').offset().top;
		var stickyNavTop = 140;

		// our function that decides weather the navigation bar should have "fixed" css position or not.
		var stickyNav = function () {
			var mainScrollTop = jQuery(window).scrollTop(); // our current vertical position from the top

			// if we've scrolled more than the navigation, change its position to fixed to stick to top,
			// otherwise change it back to relative
			if (mainScrollTop > stickyNavTop) {
				if (!jQuery('#main-top-bar').hasClass('main-nav-top-sticky')) {
					jQuery('#main-top-bar').addClass('main-nav-top-sticky');
					jQuery('#logo-container img').first().attr('src', mainThemeUrl + '/assets/media_and_docs/logo-dark.svg');
					jQuery('#logo-container img').first().attr('width', '130').attr('height', '32');
					jQuery('#top-one').addClass('one-sticky');
					if (jQuery('#wpadminbar').is(":visible") && isFullHeader) {
						jQuery('#top-one').addClass('for-wp-bar');
					}
				}
			} else {
				if (jQuery('#main-top-bar').hasClass('main-nav-top-sticky')) {
					jQuery('#main-top-bar').fadeOut(300);
					setTimeout(function () {
						jQuery('#main-top-bar').show();
						jQuery('#main-top-bar').removeClass('main-nav-top-sticky');
						if (typeof isHomePage !== 'undefined') {
							jQuery('#logo-container img').first().attr('src', mainThemeUrl + '/assets/media_and_docs/logo-dark-alt.svg');
							jQuery('#logo-container img').first().attr('width', '206').attr('height', '51');
						} else {
							jQuery('#logo-container img').first().attr('src', mainThemeUrl + '/assets/media_and_docs/logo.svg');
							jQuery('#logo-container img').first().attr('width', '206').attr('height', '51');
						}
						jQuery('#top-one').removeClass('one-sticky');
					}, 300);
					if (jQuery('#wpadminbar').is(":visible") && isFullHeader) {
						jQuery('#top-one').removeClass('for-wp-bar');
					}
					jQuery('#top-one').hide();
					jQuery('#top-one').slideDown('slow');
					jQuery('.slicknav_menu').hide();
					jQuery('.slicknav_menu').slideDown(1000);
				}
			}
		};

		if (isFullHeader) {
			// and run it again every time you scroll
			jQuery(window).scroll(function () {
				stickyNav();
			});
		} else {
			jQuery('#main-top-bar').addClass('main-nav-top-sticky');
			jQuery('#logo-container img').first().attr('src', mainThemeUrl + '/assets/media_and_docs/logo-dark.svg');
			jQuery('#logo-container img').first().attr('width', '130').attr('height', '32');
			jQuery('#top-two').height(45).css('marginTop', '0');
			jQuery('#top-one').css('position', 'fixed').css('width', '100%').css('top', '0').css('zIndex', '3');
			jQuery('#main-header').css('height', '91px').css('position', 'absolute');
			jQuery('#main-top-bar').css('top', '38px');
			jQuery('#main-div-body').addClass('header-slim');
		}

		if (jQuery('#wpadminbar').is(":visible")) {
			jQuery('#main-top-bar').addClass('main-wp-bar');
			if (!isFullHeader) jQuery('#top-one').addClass('for-wp-bar');
		} else {
			jQuery('#main-top-bar').removeClass('main-wp-bar');
			if (!isFullHeader) jQuery('#top-one').removeClass('for-wp-bar');
		}

		jQuery('#top-one').hide();
		jQuery('#top-one').slideDown('slow');
		jQuery('.slicknav_menu').hide();
		jQuery('.slicknav_menu').slideDown(1000);

	}

	//Sticky STOP

	//Paginacja
	if(jQuery('.main-pagination').length > 0) {
		jQuery('.main-pagination .next').html('<img width="40" height="40" src="' + mainThemeUrl + '/assets/media_and_docs/arrow-right.png" alt="next">');
		jQuery('.main-pagination .prev').html('<img width="40" height="40" src="' + mainThemeUrl + '/assets/media_and_docs/arrow-left.png" alt="prev">');
		jQuery('.main-pagination .page-numbers').wrapInner('<div class="inner"></div>');
	}

	//Contact Form 7 - strona kontakt
	if(jQuery('.contact-form-page').length > 0) {
		jQuery('.contact-form-page form').attr('id', 'contact-form-content').addClass('main-form');
		jQuery('.contact-form-page form textarea').removeAttr('size').removeAttr('rows').removeAttr('cols');
		jQuery('.contact-form-page form .form-control').removeAttr('size');
		jQuery('.contact-form-page label').click(function() {
			jQuery(this).parent().siblings('input').focus();
		});
		jQuery('.contact-form-page .form-control').focus(function() {
			jQuery(this).parent().siblings('.main-form label').hide();
		});
		jQuery('.contact-form-page .form-control').blur(function() {
			var $thisFc = jQuery(this);
			if ($thisFc.val().length == 0)
				jQuery(this).parent().siblings('.main-form label').show();
		});
	}

	//Contact Form 7 - stopka
	if(jQuery('#contact-form-bottom').length > 0) {
		jQuery('#contact-form-bottom textarea').removeAttr('size').removeAttr('rows').removeAttr('cols');
		jQuery('#contact-form-bottom .form-control').removeAttr('size');
		jQuery('#contact-form-bottom label').click(function() {
			jQuery(this).parent().siblings('input').focus();
		});
		jQuery('#contact-form-bottom .form-control').focus(function() {
			jQuery(this).parent().siblings('.main-form label').hide();
		});
		jQuery('#contact-form-bottom .form-control').blur(function() {
			var $thisFc = jQuery(this);
			if ($thisFc.val().length == 0)
				jQuery(this).parent().siblings('.main-form label').show();
		});
	}

	//Contact Form 7 - szybki kontakt
	if(jQuery('#contact-form-top').length > 0) {
		jQuery('#contact-form-top .form-control').removeAttr('size');
		jQuery('#contact-form-top label').click(function() {
			jQuery(this).parent().siblings('input').focus();
		});
		jQuery('#contact-form-top .form-control').focus(function() {
			jQuery(this).parent().siblings('.main-form label').hide();
		});
		jQuery('#contact-form-top .form-control').blur(function() {
			var $thisFc = jQuery(this);
			if ($thisFc.val().length == 0)
				jQuery(this).parent().siblings('.main-form label').show();
		});
	}

	//SlickCarousel a START

	if(jQuery('.carousel-one').length > 0) {
		jQuery('.carousel-one').slick({
			autoplay: false,
			arrows: true,
			dots: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
			]
		});
	}

	if(jQuery('.recommended').length > 0) {
		jQuery('.recommended').slick({
			autoplay: false,
			arrows: true,
			dots: true,
			slidesToShow: 2,
			slidesToScroll: 2,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
			]
		});
	}

	if(jQuery('.carousel-articles').length > 0) {
		jQuery('.carousel-articles').slick({
			autoplay: false,
			arrows: true,
			dots: true,
			slidesToShow: 2,
			slidesToScroll: 2,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
			]
		});
	}

	if(jQuery('#slider-header').length > 0) {
		jQuery('#slider-header').slick({
			autoplay: true,
			arrows: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplaySpeed: 6000,
			pauseOnHover: false,
		});
	}

	//SlickCarousel a STOP

	//Slicknav START

	jQuery("#main-menu-top > ul").slicknav({
		prependTo: '#top-two',
		label: '',
		closeOnClick: true,
		allowParentLinks: true,
	});
	jQuery('.slicknav_menu').prepend('<a href="' + mainRootUrl + '" id="mob-logo"><img src="' + mainThemeUrl + '/assets/media_and_docs/logo-dark.svg" width="130" height="32" alt="Logo"></a>');

	//Slicknav STOP

	// Spis treści do artykułu
	if(jQuery('#spis-tresci').length > 0) {
		var listContainer = jQuery('#main-container-list');
		var headerList = jQuery('#main-article-content h2, .col-article-content h2');
		if(headerList.length) {
			listContainer.append('<ul id="lista-spis-tresci" class="list-one mt-0 mb-0">');
			headerList.each(function (index) {
				var parseAnchor = replacePl(jQuery(this).text());
				parseAnchor = parseAnchor.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z\-]/g, "");
				jQuery(this).attr('id', parseAnchor);
				jQuery('#lista-spis-tresci').append('<li><a href="#' + parseAnchor + '">' + jQuery(this).text());
			});
		}
	}

	//Kotwice h2
	var mainSections = jQuery('body .main-section');
	var sectionHeader = null;
	var parseAnchorSec = null;
	if(mainSections.length) {
		mainSections.each(function (index) {
			sectionHeader = jQuery(this).find('h2').first();
			if(!sectionHeader.attr("id")) {
				parseAnchorSec = replacePl(sectionHeader.text());
				parseAnchorSec = parseAnchorSec.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z\-]/g, "");
				sectionHeader.attr('id', parseAnchorSec);
			}
		});
	}

	///cookie warn
	if(!getCookie('cookieWarn.accepted')) {
		let intervalCookie = setInterval(function () {
			layoutCookieWarn();
			if(cookieWarnComplete()) clearInterval(intervalCookie);
		}, 200);
	}

	if(jQuery('.center-line').length > 0) {
		jQuery('.main-section.center-line').prepend('<div class="center-line-before">').append('<div class="center-line-after">');
	}

	if(jQuery('.section-img-mobile').length > 0) {
		jQuery('.section-img-mobile').each(function () {
			var thisSim = jQuery(this);
			if(thisSim.parent().find('.p-row').length) {
				thisSim.css('paddingTop', 0);
			}
		});
	}

	if(jQuery('.main-form').length > 0) {
		jQuery('.main-form .button-ateneo').unwrap('p');
		jQuery('.main-form .button-ateneo').each(function() {
			let thisBtn = jQuery(this);
			thisBtn.prepend(thisBtn.siblings('.btn-title').first());
		});
		jQuery('.main-form label').unwrap('p');
	}

	mainResize();
	
});

window.onload = function () {
	jQuery('#main-loader').remove();
	setPostImage();
	setBottomPage();
	//Dostosowanie sekcji 3 START
	var tcThis, tcBlock, tcImage, tcSectionHeight;

	if(jQuery('.section-type-c').length > 0) {
		jQuery('.section-type-c').each(function () {
			tcThis = jQuery(this);
			tcBlock = tcThis.find('.item-col-content .box-area > div').first();
			tcImage = tcThis.find('.item-col-image > div img').first();
			if(tcImage.height() > tcBlock.height()) {
				if((tcImage.height() - tcBlock.height()) > 300) {
					tcSectionHeight = tcBlock.height() + 300;
					tcImage.css('maxHeight', tcSectionHeight);
				}
			}
		});
	}

	//Dostosowanie sekcji 3 STOP
	jQuery(window).resize(function() {
		setPostImage();
		setBottomPage();
	});
};

jQuery(window).resize(function() {
	mainResize();
});


//Funkcje

function mainResize() {
	var wwidth = jQuery(window).width();

	if(isFullHeader) {
		if (typeof isHomePage !== 'undefined') {
			setHeaderIntroHeight(0.4);
			setTopTwoHeight(0.4);
		} else {
			setHeaderIntroHeight();
			setTopTwoHeight();
		}
		replaceHeaderBottom();
		if (wwidth <= 767) {
			setHeaderIntroHeight(1.4);
			setTopTwoHeight(1.4);
			if (typeof isHomePage === 'undefined') {
				setHeaderIntroHeight();
				setTopTwoHeight();
			}
		}
	}

	if(jQuery('.section-type-c').length > 0) {
		var sectionC, stcColImage, stcBtnLink, stcDivImage;
		sectionC = jQuery('.section-type-c');
		if (wwidth <= 767) {
			sectionC.each(function () {
				stcBtnLink = jQuery(this).find('.button-ateneo').first();
				stcDivImage = jQuery(this).find('.item-div-image').first();
				placementObjectBefore(stcDivImage, stcBtnLink);
			});
		} else {
			sectionC.each(function () {
				stcColImage = jQuery(this).find('.item-col-image').first();
				stcDivImage = jQuery(this).find('.item-div-image').first();
				placementObjectAppend(stcDivImage, stcColImage);
			});
		}
	}

	// if(jQuery('.fb-page').length > 0) {
	// 	if (wwidth <= 550) {
	// 		jQuery('.fb-page').attr('data-width', '295');
	// 	} else {
	// 		jQuery('.fb-page').attr('data-width', '504');
	// 	}
	// }

	sliderTop(wwidth);
	mobileImages(wwidth);
	setWidthDotSliderTop(wwidth);
}

function placementObjectBefore(element, elementBefore) {
	jQuery(elementBefore).before(jQuery(element));
}

function placementObjectAfter(element, elementAfter) {
	jQuery(elementAfter).after(jQuery(element));
}

function placementObjectAppend(element, elementAppend) {
	jQuery(elementAppend).append(jQuery(element));
}

function placementObjectPrepend(element, elementPrepend) {
	jQuery(elementPrepend).prepend(jQuery(element));
}

function setHeaderIntroHeight(factor = 0) {
	var headerWidth = jQuery('#main-header').width();
	if(!factor) jQuery('#header-intro').height((headerWidth*.516) - jQuery('#top-one').height());
	else jQuery('#header-intro').height((headerWidth*factor) - jQuery('#top-one').height());
}

function setTopTwoHeight(factor = 0) {
	var headerWidth = jQuery('#main-header').width();
	if(!factor) jQuery('#top-two').height((headerWidth*.516) - jQuery('#top-one').height());
	else jQuery('#top-two').height((headerWidth*factor) - jQuery('#top-one').height());
}

function replaceHeaderBottom(factor = 0) {
	var headerWidth = jQuery('#main-header').width();
	var trgVert = (headerWidth*.516) * .25;
	if(factor) trgVert = (headerWidth*factor) * .25;
	if (typeof isHomePage === 'undefined') {
		jQuery('#contact-form-top').css('marginTop', -trgVert + 'px');
		jQuery('.hdi-body').first().css('paddingBottom', (trgVert+60) + 'px');
	}
}

function setPostImage() {
	if(jQuery('.single-excerpt').length > 0) {
		var singleExc = jQuery('.single-excerpt').first();
		var seHeightOne = (singleExc.offset().top + singleExc.height()) - jQuery('#main-header').height();
		jQuery('.article-single .box-image').first().height(seHeightOne);
	}
}

function setBottomPage() {
	if(jQuery('#cookieScriptBox').length > 0) {
		setTimeout(function () {
			var cookieSB = jQuery('#cookieScriptBox');
			if(cookieSB.hasClass('loaded')) {
				//jQuery('body').first().css('paddingBottom', (cookieSB.height()+20));
			}
		}, 800);
	}
}

function layoutCookieWarn() {
	if(jQuery('#cookieScriptBox').length > 0) {
		cookieSBF = jQuery('#cookieScriptBox');
		if(cookieSBF.hasClass('loaded') && !cookieSBF.find('.item-image').length) {
			cookieSBF.prepend('<div class="item-image"><img src="' + mainThemeUrl + '/assets/media_and_docs/cookie.png" alt="Cookie" width="66" height="66"></div>');
			cookieSBF.append(cookieSBF.find('#cookieScriptAccept').first());
			jQuery('#cookieScriptAccept').wrapAll('<div class="item-button"></div>');
			cookieSBF.find('.text').first().addClass('item-text');
		}
	}
}

function cookieWarnComplete() {
	let warnBox = jQuery('#cookieScriptBox');
	if(!warnBox.find('.item-image').length) return false;
	return true;
}

function mobileImages(wwidth) {
	if(jQuery('.section-container-image').length || jQuery('.container-image').length || jQuery('.inner-bg-image').length) {
		var typeItem;
		if (wwidth <= 767) {
			//First type images mob START
			jQuery('.main-section .section-container-image').each(function () {
				typeItem = jQuery(this);
				if(!typeItem.parent().find('.img-mob-type').length) typeItem.parent().find('h2').first().after(typeItem.find('img').first().addClass('mb-4 img-mob-type'));
			});
			//First type images mob STOP
			//Two type images mob START
			jQuery('.main-section .container-image').each(function() {
				typeItem = jQuery(this);
				if(!typeItem.parent().find('.img-mob-type').length) typeItem.parent().find('h2').first().after('<img src="' + typeItem.css('background-image').split(/"/)[1] + '" class="mb-4 img-mob-type" alt="' + typeItem.parent().find('h2').first().text() + '">');
			});
			//Two type images mob STOP
			//Three type images mob START
			jQuery('.main-section .inner-bg-image').each(function() {
				typeItem = jQuery(this);
				if(!typeItem.find('.img-mob-type').length) typeItem.find('h2').first().after('<img src="' + typeItem.css('background-image').split(/"/)[1] + '" class="mb-4 img-mob-type" alt="' + typeItem.find('h2').first().text() + '">');
			});
			//Three type images mob STOP
		} else {
			//First type images desk START
			jQuery('.main-section .section-container-image').each(function () {
				typeItem = jQuery(this);
				if(!typeItem.find('img').length) typeItem.append(typeItem.parent().find('h2').first().next('.img-mob-type'));
			});
			//First type images desk STOP
			//Two type images desk START
			jQuery('.main-section .container-image').each(function() {
				typeItem = jQuery(this);
				if(typeItem.parent().find('.img-mob-type').length) typeItem.parent().find('h2').first().next('.img-mob-type').remove();
			});
			//Two type images desk STOP
			//Three type images desk START
			jQuery('.main-section .inner-bg-image').each(function() {
				typeItem = jQuery(this);
				if(typeItem.find('.img-mob-type').length) typeItem.find('h2').first().next('.img-mob-type').remove();
			});
			//Three type images desk STOP
		}
	}
}

function sliderTop(wWidth) {
	if(jQuery('#slider-header').length > 0) {
		let thisIt;
		if(wWidth <= 767) {
			jQuery('#slider-header .item').each(function () {
				thisIt = jQuery(this);
				if(thisIt.find('.hdi-image').first().data('rwd') != '')
					thisIt.css('backgroundImage', 'url(' + thisIt.find('.hdi-image').first().data('rwd') + ')');
				else thisIt.css('backgroundImage', 'url(' + thisIt.find('.hdi-image').first().attr('src') + ')');
			});
		} else {
			jQuery('#slider-header .item').css('backgroundImage', '');
		}
	}
}

function setWidthDotSliderTop(wWidth) {
	if(jQuery('#slider-header').length > 0) {
		if(wWidth < 768) {
			let dstItems = jQuery('#slider-header .slick-dots > li').length;
			let dstWidth = Math.floor(wWidth/dstItems);
			dstWidth = dstWidth - 20;
			jQuery('#slider-header .slick-dots > li > button').width(dstWidth);
		}
	}
}

function replacePl(str)
{
	return str
	.replace("Ę","E")
	.replace("Ó","O")
	.replace("Ą","A")
	.replace("Ś","S")
	.replace("Ł","L")
	.replace("Ż","Z")
	.replace("Ź","Z")
	.replace("Ć","C")
	.replace("Ń","N")
	.replace("ę","e")
	.replace("ó","o")
	.replace("ą","a")
	.replace("ś","s")
	.replace("ł","l")
	.replace("ż","z")
	.replace("ź","z")
	.replace("ć","c")
	.replace("ń","n");
}

function getCookie(name) {
	// Split cookie string and get all individual name=value pairs in an array
	var cookieArr = document.cookie.split(";");

	// Loop through the array elements
	for(var i = 0; i < cookieArr.length; i++) {
		var cookiePair = cookieArr[i].split("=");

		/* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
		if(name == cookiePair[0].trim()) {
			// Decode the cookie value and return
			return decodeURIComponent(cookiePair[1]);
		}
	}

	// Return null if not found
	return null;
}